@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #edf7fa;
}
/* scroller style
#style-11::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-11::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-11::-webkit-scrollbar-thumb {
  background-color: #3366ff;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
} */

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}
.grid-cols-4 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.img-product {
  max-width: 14rem;
  height: 21rem;
  object-fit: contain;
  font-size: 14px;
  /* padding: 55px; */
}
.product-name {
  font-size: 14px;
}
.scrollbar-thin {
  scrollbar-width: auto;
}
.top-36 {
  top: 4rem;
}
.w-32 {
  width: 6rem;
}
.h-20 {
  height: 8rem;
}
/* .slick-slide img {
  padding: 30px;
  width: 21rem;
  height: 21rem;
  object-fit: contain;
} */
.slick-next {
  display: none !important;
}
.slick-prev {
  display: none !important;
}
.brands-sidbar {
  margin-left: 166px;
  position: absolute;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
  top: 1rem;
}
.logo {
  max-width: 100%;
  height: auto;
}

.Arrow {
  opacity: 35%;
}
/* s */

@media (min-width: 768px) and (max-width: 1024px) {
  .profile-res {
    position: absolute;
    margin-left: 580px;
    top: 31px;
  }
  .profile-box {
    margin-left: -94px;
  }
}
@media (min-width: 360px) and (max-width: 740px) {
  .profile-res {
    position: absolute;
    margin-left: 265px;
    top: 31px;
  }
  .ser {
    width: 82%;
  }
  .prd-name {
    display: none;
  }
}

@media (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
  .img-product {
    max-width: 100%;
    height: 10rem;
    /* object-fit: contain; */
    padding: 22px;
  }
  .grid-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .profile-res {
    position: absolute;
    margin-left: 265px;
    top: 31px;
  }
  .profile-box {
    margin-left: -94px;
  }
  .prd-name {
    display: none;
  }
}

@media (max-width: 576px) {
}

/* ============= NavLink active End here ================ */

@media (max-width: 768px) {
  .category-button {
    display: none;
  }
  .img-product {
    max-width: 100%;
    height: 10rem;
    /* object-fit: contain; */
    padding: 22px;
  }
  .grid-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .spec-img {
    height: 29rem;
    width: 15rem;
    object-fit: contain;
    margin-left: 0px;
  }
}
.spec-img {
  height: 29rem;
  width: 15rem;
  object-fit: contain;
  margin-left: 77px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ========== Product ========== */
.strip {
  display: flex;
  background: linear-gradient(to right, #0a6075, hsl(0, 0%, 4%));
  padding: 10px;
  align-items: center;
  border-radius: 10px;
}

.icon-container {
  text-align: center;
  position: relative;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.icon-container:hover {
  transform: scale(1.2);
}

.icon {
  font-size: 24px;
  color: white;
}

.name {
  color: white;
  text-align: center;
  margin-top: 5px;
  /* margin-left: 2.25rem; */
  text-decoration: none;
}

/* .popup {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
} */

.icon-container:hover .popup {
  display: block;
}
/* ====== media Query ====== */
@media (max-width: 768px) {
  .displayprd {
    display: none;
  }
}
@media (max-width: 576px) {
  .displayprd {
    display: none;
  }
  .spec-img {
    height: 29rem;
    width: 15rem;
    object-fit: contain;
    margin-left: 0px;
  }
}
@media (max-width: 992px) {
  .displayprd {
    display: none;
  }
}
@media (min-width: 768) and (max-width: 1200px) {
  .profile {
    display: none;
  }
}
.p-6 {
  overflow: auto;
}

/* ======== product card ======= */

/* ======= My List ===== */

.my-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.my-list-item {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 0.5rem;
  padding: 3rem;
  width: calc(17% - 1rem);
  height: 50px;
  box-sizing: border-box;

  color: #fff;
  cursor: pointer;
}
.smart-tv {
  background: #022845;
  background-image: linear-gradient(152deg, #00c4ff 4%, #071e2f 92%);
}
.Mobiles {
  background: #20abe2;
  background-image: linear-gradient(140deg, #20e2d7 -7%, #20abe2 96%);
}
.Laptops {
  background: #11b1ae;
  background-image: linear-gradient(-45deg, #11b1ae, #96de56);
}
.Tablets {
  background: #4f8925;
  background-image: linear-gradient(-45deg, #659c3d, #dcbc03);
}
.Routers {
  background: #f78600;
  background-image: linear-gradient(-45deg, #f78600, #5d4606);
}
.Printers {
  background: #f75d34;
  background-image: linear-gradient(-45deg, #f75d34, #943f07);
}
.Earbuds {
  background: #ff4c51;
  background-image: linear-gradient(-45deg, #ff4c51, #b43411);
}
.Extenders {
  background: #330867;
  background-image: linear-gradient(-45deg, #330867, #7af8f8);
}
.Graphic {
  background: rgb(87, 228, 193);
  background: linear-gradient(
    45deg,
    rgba(87, 228, 193, 1) 0%,
    rgba(10, 96, 117, 1) 100%
  );
}

@media (max-width: 600px) {
  .my-list-item {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 400px) {
  .my-list-item {
    width: calc(50% - 1rem);
  }
}
.svg-inline--fa {
  height: 1.5em;
}
/* ======= End My List ===== */

/* ======= heading ======= */
.Heading-text {
  color: #0a6075;
  font-size: 2rem;
  font-weight: 600;
  font-family: sans-serif;
}
.view {
  color: #00c4ff;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid grey;
}
.prd-hed {
  background-color: #0a6075;
  border-radius: 15px;
  color: #fff;
}

/* ======= End heading ======= */

/* Logo Slider */

.image-brand {
  width: 100px;
}
.logo-slider {
  background: white;
  -webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.logo-slider::before,
.logo-slider::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 175px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.logo-slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.logo-slider::before {
  left: 0;
  top: 0;
}

.logo-slider .logo-slide-track {
  -webkit-animation: logo-scroll 60s linear infinite;
  animation: logo-scroll 60s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(250px * 14);
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.logo-slider .slide {
  height: 150px;
  width: 250px;
  padding: 20px;
}

@-webkit-keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

/* End Logo Slider */

/* ======== Brand ========== */
.brand {
  max-width: 600px; /* Adjust the maximum width as needed */
  margin: 0 auto;
  text-align: center;
}

.logo-slider {
  overflow: hidden;
  position: relative;
}

.logo-slide-track {
  display: flex;
  transition: transform 0.5s ease; /* Adjust the duration and timing function as needed */
}

.slide {
  flex: 0 0 100%;
}

.image-brand {
  width: 100%;
  height: auto;
}

.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
}

.slider-controls p {
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* ========= End Brand =========== */

/* MobileBrandsSlider.css */

.custom-slider .slick-slide {
  padding: 10px;
}

.custom-slider .slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}
.custom-slider .slide-content:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #0a6075;
  cursor: pointer;
  font-weight: 600;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: underline;
}

.custom-slider .slide-content img {
  width: 12rem;
  height: 12rem;
}

.custom-slider .slide-content p {
  text-align: center;
  margin-top: 10px;
}

.custom-slider .slick-dots {
  display: none;
}

@media screen and (max-width: 1024px) {
  .custom-slider .slick-slide {
    padding: 5px;
  }

  .custom-slider .slide-content img {
    width: 50%;
  }
}

/* @media screen and (max-width: 600px) {

  .custom-slider .slide-content img {
    width: 75%;
  }
  .custom-slider .slide-content img {
    width: 100%;
    height: auto;
    padding: 60px;
  }
  .slick-slide img {
    padding: 63px;
  }
} */

@media screen and (max-width: 480px) {
  .custom-slider .slick-slide {
    padding: 2px;
  }

  .custom-slider .slide-content img {
    width: 100%;
  }
  .custom-slider .slide-content img {
    width: 100%;
    height: auto;
  }
}

/* ========= home banner ======== */
.main-banner {
  border-bottom: 3px dotted #eee;
  padding-top: 40px;
  padding-bottom: 30px;
}

.main-banner .left-content .thumb img {
  width: 100%;
  overflow: hidden;
}

.main-banner .left-content .inner-content {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
}

.main-banner .left-content .inner-content h4 {
  color: #fff;
  margin-top: -10px;
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 20px;
}

.main-banner .left-content .inner-content span {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  font-style: italic;
  display: block;
  margin-bottom: 30px;
}

.main-banner .right-content .right-first-image {
  margin-bottom: 28.5px;
}

.main-banner .right-content .right-first-image .thumb {
  position: relative;
  text-align: center;
}

.main-banner .right-content .right-first-image .thumb img {
  width: 100%;
  overflow: hidden;
}

.main-banner .right-content .right-first-image .thumb .inner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.main-banner .right-content .right-first-image .thumb .inner-content h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.main-banner .right-content .right-first-image .thumb .inner-content span {
  font-size: 16px;
  color: #fff;
  font-style: italic;
}

.main-banner .right-content .right-first-image .thumb .hover-content {
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  text-align: center;
  background-color: rgba(42, 42, 42, 0.97);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.main-banner .right-content .right-first-image .thumb:hover .hover-content {
  opacity: 1;
  visibility: visible;
}

.main-banner .right-content .right-first-image .thumb .hover-content .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.main-banner .right-content .right-first-image .thumb .hover-content h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.main-banner .right-content .right-first-image .thumb .hover-content p {
  color: #fff;
  padding: 0px 20px;
  margin-bottom: 20px;
}
.compare-btn {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #0a6075;
  animation: forwards;
  transition: all ease-in-out 0.3s;
}
.compare-btn:hover {
  /* background-color: #0a6075; */
  color: #fff;
  box-shadow: 2px 3px 6px 2px gray;
}
.wishlist-btn {
  padding: 10px;
  border-radius: 13px;
  border: 1px solid #0a6075;
  background-color: #0a6075;
  color: #fff;
  transition: all ease-in-out 0.3s;
}
.wishlist-btn:hover {
  padding: 10px;
  border-radius: 13px;
  border: 1px solid #0a6075;
  background-color: #0a6075;
  color: white;
  box-shadow: 2px 3px 6px 2px gray;
}

/* ================== Add Product ======================= */

#tabs .active {
  border-color: #0072bc;
  color: #0072bc;
  font-weight: bold;
}
.faq-question {
  border: 1px solid rgba(186, 184, 184, 0.246);
  padding: 10px;
  width: 60%;
  cursor: pointer;
}
.faq-answer {
  border: 1px solid rgba(186, 184, 184, 0.246);
  padding: 10px;
  width: 80%;
  cursor: pointer;
}
.tip-title {
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 766px) {
  .faq-question {
    border: 1px solid rgba(186, 184, 184, 0.246);
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
  .faq-answer {
    border: 1px solid rgba(186, 184, 184, 0.246);
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
}

.printer-compatibility-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons-container {
  margin-bottom: 20px;
}

.buttons-container button {
  margin-right: 10px;
  background-color: transparent;
  border: 1px solid #0a6075;
  color: #0a6075;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.buttons-container button.active {
  background-color: #0a6075;
  color: white;
}

.info-container {
  width: 80%;
}

.info-container h2 {
  font-size: 35px;
  margin-bottom: 10px;
  font-family: Arial, sans-serif; /* Example font-family */
  font-weight: bold; /* Example font-weight */
  color: rgba(161, 161, 161, 0.918);
  text-align: center;
  /* margin-bottom: 30px; */
}

.info-container p {
  font-size: 16px;
  font-family: Arial, sans-serif; /* Example font-family */
  line-height: 1.6; /* Example line-height */
}

.compatibility-table {
  width: 100%;
  border-collapse: collapse;
}

.compatibility-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .buttons-container {
    flex-direction: column;
    align-items: center;
  }

  .buttons-container button {
    margin: 5px 0;
  }
  .brand-heading {
    font-size: 20px;
    font-weight: 600;
    color: rgba(131, 131, 131, 0.788);
  }
  .cg-banner {
    margin-left: 0px !important;
  }
}
.prd-search {
  margin-bottom: -24px;
}
.prd-text {
  border-left: 1px solid grey;
}
.load {
  border: 2px solid #0a6075;
  border-radius: 15px;
}
.load:hover {
  border: 2px solid #2cb8db;
  border-radius: 15px;
}

#slider {
  gap: 5px;
  font-size: 30px;
}
.cg-banner {
  margin-left: -115px;
}

#sliderValue {
  display: flex;
  color: #0a6075;
  font-weight: 700;
}

.start {
  opacity: 0;
}

.animation {
  animation: fade 0.3s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.holder-animation {
  animation: holder 4s;
}

@keyframes holder {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.hello {
  white-space: normal;
  overflow-wrap: break-word;
}
.hello-rev {
  white-space: nowrap;
  overflow: hidden;
}

.pros-text {
  color: #0a6075;
  font-size: 2rem;
  font-weight: 600;
  font-family: sans-serif;
}
.pros-tick {
  color: green;
  /* position: absolute; */
}
.pros-close {
  color: red;
  position: absolute;
  font-size: 40px;
}
.fade-com {
  z-index: 6;
  transition-duration: 0ms;
  display: block;
  opacity: 0.6;
}

/* ================== Search ================= */

/* Search.css */

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Provides space between text and image sections */
  /* padding: 50px;  */
  background-color: #f0f0f0; /* Replace with the desired background color */
  padding-right: 50px;
  padding-left: 60px;
}

.text-section {
  flex: 1; /* Takes up half the space in the container */
  max-width: 600px; /* Maximum width of the text section */
}

.text-section h1 {
  font-size: 2.5rem; /* Large font size for the main heading */
  color: #333; /* Dark color for text */
  margin-bottom: 0.5rem;
}

.text-section h2 {
  font-size: 1.8rem; /* Font size for the subheading */
  color: #555; /* Slightly lighter color for the subheading */
  margin-bottom: 1.5rem;
}

.search-box {
  position: relative;
  width: 100%;
}

.search-box input[type="text"] {
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
  border: 2px solid #ddd; /* Border color */
  border-radius: 20px; /* Fully rounded borders */
  box-sizing: border-box; /* Ensures padding doesn't affect the total width */
}

.image-section {
  flex: 1; /* Takes up the other half of the space in the container */
  display: flex; /* Ensures the image centers itself in the div */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.books-img {
  max-width: 100%; /* Ensures image is responsive and does not overflow */
  height: 25rem; /* Keeps the image aspect ratio */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .hero-container {
    flex-direction: column; /* Stack the sections vertically on small screens */
  }

  .text-section,
  .image-section {
    max-width: 100%;
  }

  .search-box input[type="text"] {
    width: calc(100% - 40px); /* Subtract padding from total width */
  }
}

/* Make the search box container position relative to position the suggestions dropdown */
.search-box {
  position: relative;
  width: 100%; /* Adjust width as needed */
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  z-index: 20;
  max-height: 300px;
  overflow-y: auto; /* Enable scroll for overflow */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-suggestions::-webkit-scrollbar {
  width: 0px; /* Remove vertical scrollbar for webkit browsers */
}

.search-suggestions li {
  padding: 10px;
  cursor: pointer;
  list-style-type: none;
  text-align: start;
}

.search-suggestions li:hover {
  background-color: #f0f0f0;
}

/* ================== Search end ================= */

/* ================== Comment  ================= */

.review-popup-btn {
  /* position: fixed; */
  bottom: 20px;
  right: 20px;
  background-color: #ffc107;
  color: #fff;
  border: none;
  border-radius: 10%;
  width: auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease-out;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.review-popup-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}

.review-popup-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.review-popup {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 40%;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* z-index: 1000; */

  transition: transform 0.3s ease-out;
}

.review-popup.open {
  transform: translateY(0);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
}

.popup-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}

.review-popup form {
  padding: 20px;
}

.review-popup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.review-popup textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
  margin-bottom: 15px;
}

.review-popup .star-rating {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review-popup .star-rating label {
  font-size: 30px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.review-popup .star-rating input[type="radio"] {
  display: none;
}

.review-popup .star-rating input[type="radio"]:checked ~ label {
  color: #ffc107;
}

.review-popup .submit-btn {
  background-color: #ffc107;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.review-popup .submit-btn:hover {
  background-color: #e0b305;
}

.star-rating {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.star-rating label {
  font-size: 30px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.star-rating input[type="radio"] {
  display: none;
}

.star-rating input[type="radio"]:checked ~ label {
  color: #ffc107;
}
.popup-text {
  font-size: 2rem;
}

.rating {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  flex-direction: row-reverse;
  height: 150px;
  position: relative;
}
.rating-0 {
  filter: grayscale(100%);
}
.rating > input {
  display: none;
}
.rating > label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
  background-image: url("data:image/svg+xml;
 charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}
.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;
 charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}
.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;
 charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.emoji-wrapper {
  width: 100%;
  text-align: center;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.emoji-wrapper:before,
.emoji-wrapper:after {
  content: "";
  height: 15px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}
.emoji-wrapper:before {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.emoji-wrapper:after {
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
.emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}
.emoji > svg {
  margin: 15px 0;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}
#rating-1:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-100px);
}
#rating-2:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-200px);
}
#rating-3:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-300px);
}
#rating-4:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-400px);
}
#rating-5:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-500px);
}
.feedback {
  max-width: 360px;
  background-color: #fff;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
}

/* ================ Comments ================= */

/** ====================
 * Lista de Comentarios
 =======================*/
.comments-container {
  margin: -50px auto 15px;
  width: 768px;
}

.comments-container h1 {
  font-size: 36px;
  color: #283035;
  font-weight: 400;
}

.comments-container h1 a {
  font-size: 18px;
  font-weight: 700;
}

.comments-list {
  margin-top: 20px;
  position: relative;
}

/**
 * Lineas / Detalles
 -----------------------*/
.comments-list:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #c7cacb;
  position: absolute;
  /* left: 32px; */
  top: 0;
}

.comments-list:after {
  content: "";
  position: absolute;
  background: #c7cacb;
  bottom: 0;
  left: 27px;
  width: 7px;
  height: 7px;
  border: 3px solid #dee1e3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.reply-list:before,
.reply-list:after {
  display: none;
}
.reply-list li:before {
  content: "";
  width: 60px;
  height: 2px;
  background: #c7cacb;
  position: absolute;
  top: 25px;
  left: -55px;
}

.comments-list li {
  margin-bottom: 15px;
  display: block;
  position: relative;
}

.comments-list li:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  width: 0;
}

.reply-list {
  padding-left: 88px;
  clear: both;
  margin-top: 15px;
}
/**
 * Avatar
 ---------------------------*/
.comments-list .comment-avatar {
  width: 65px;
  height: 65px;
  position: relative;
  /* z-index: 99; */
  float: left;
  border: 3px solid #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.comments-list .comment-avatar img {
  width: 100%;
  height: 100%;
}

.reply-list .comment-avatar {
  width: 50px;
  height: 50px;
}

.comment-main-level:after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  clear: both;
}
/**
 * Caja del Comentario
 ---------------------------*/
.comments-list .comment-box {
  width: auto;
  /* float: right; */
  padding: 25px;
  position: relative;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comments-list .comment-box:before,
.comments-list .comment-box:after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  display: block;
  border-width: 10px 12px 10px 0;
  border-style: solid;
  border-color: transparent #fcfcfc;
  top: 8px;
  left: -11px;
}

.comments-list .comment-box:before {
  border-width: 11px 13px 11px 0;
  border-color: transparent rgba(0, 0, 0, 0.05);
  left: -12px;
}

.reply-list .comment-box {
  width: 610px;
}
.comment-box .comment-head {
  background: #fcfcfc;
  padding: 10px 12px;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
  float: right;
  margin-left: 14px;
  position: relative;
  top: 2px;
  color: #a6a6a6;
  cursor: pointer;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
  color: #03658c;
}

.comment-box .comment-name {
  color: #283035;
  font-size: 14px;
  font-weight: 700;
  float: left;
  margin-right: 10px;
}

.comment-box .comment-name a {
  color: #283035;
}

.comment-box .comment-head span {
  float: left;
  color: #999;
  font-size: 13px;
  position: relative;
  top: 1px;
}

.comment-box .comment-content {
  background: #fff;
  padding: 12px;
  font-size: 15px;
  color: #595959;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.comment-box .comment-name.by-author,
.comment-box .comment-name.by-author a {
  color: #03658c;
}
.comment-box .comment-name.by-author:after {
  content: "autor";
  background: #03658c;
  color: #fff;
  font-size: 12px;
  padding: 3px 5px;
  font-weight: 700;
  margin-left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

/** =====================
 * Responsive
 ========================*/
@media only screen and (max-width: 766px) {
  .comments-container {
    width: 370px;
  }

  .comments-list .comment-box {
    width: 390px;
  }

  .reply-list .comment-box {
    width: 320px;
  }
  .feedback {
    margin-left: -37px;
  }
}
@media only screen and (max-width: 600px) {
  .comments-container {
    width: 400px;
  }
  .feedback {
    margin-left: -37px;
  }
}

/* ================== Comment end ================= */

/* ================== Brand  ================= */

/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f8f9fa;
} */

.one {
  margin: 0 auto;
  max-width: 1300px;
  padding: 100px 20px;
  padding-top: 200px;
}

.title {
  margin-bottom: 70px;
}

.title h3 {
  position: relative;
  font-size: 45px;
  font-weight: 900;
}

.title h3 .bg {
  position: absolute;
  left: -6px;
  top: 0px;
  color: transparent;
  font-size: 150px;
  font-weight: 900;
  opacity: 0.1;
  line-height: 0;
  -webkit-text-stroke: 1px #000;
  -webkit-user-select: none;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.card {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  /* padding: 10px; */
}
.card:hover {
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.16);
  transform: translate(0, -5px);
  transition-delay: 0s !important;
}

.card img {
  height: 250px;
  width: 100%;
  object-fit: contain;
}

.card-body {
  background-color: #fff;
  padding: 10px;
  height: 200px;
}

.card-body .text-date {
  font-size: 14px;
  color: #888;
  margin-bottom: 5px;
  line-height: 1.7;
}

.line {
  height: 1px;
  width: 100%;
  background-color: #999;
  margin-bottom: 20px;
}

.card-body .text {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .One {
    padding: 50px 20px;
    padding-top: 80px;
    width: auto;
  }
}
@media screen and (max-width: 1250px) {
  #sliderValue {
    display: flex;
    color: #0a6075;
    font-weight: 700;
    margin-left: 70px;
  }
}

@media screen and (max-width: 600px) {
  .cards {
    grid-template-columns: 1fr;
  }

  .one {
    padding: 30px 20px;
    width: auto;
  }

  .title {
    margin-bottom: 30px;
  }

  .title h3 .bg {
    display: none;
  }
}

/* ================== Brand end ================= */

/* ================== News  ================= */

/* News.css */
.news-section {
  padding: 20px;
  margin: auto;
  max-width: 1200px; /* Adjust the max width as needed */
  box-sizing: border-box;
}

.news-section h2 {
  margin-bottom: 20px;
  font-size: 2rem; /* Responsive font size */
}
.news-section h4 {
  margin-bottom: 20px;
  font-size: 1rem; /* Responsive font size */
  text-decoration: none;
  text-align: end;
}

/* Styling for the news card */
.news-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 40rem;
  text-decoration: none;
}

.news-card:hover {
  transform: scale(1.03); /* A slight zoom effect on hover */
}

.news-image {
  width: 100%;
  height: 180px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.news-info {
  padding: 15px;
  background: #fff;
  text-decoration: none;
}

.news-title {
  font-size: 1.1rem; /* Responsive font size */
  margin-bottom: 10px;
  color: #333;
  text-decoration: none;
}

.news-summary {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
  text-decoration: none;
  text-align: justify;
}

.news-meta {
  font-size: 0.875rem;
  color: #999;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .news-section {
    padding: 10px;
  }

  .news-image {
    height: 150px;
  }

  .news-title {
    font-size: 1.1rem;
  }

  .news-summary {
    font-size: 0.9rem;
  }

  .news-meta {
    font-size: 0.8rem;
  }
}

@media (max-width: 600px) {
  .news-image {
    height: 120px;
  }

  .news-title {
    font-size: 1rem;
  }

  .news-summary,
  .news-meta {
    font-size: 0.8rem;
  }
}

.Heading-text {
  color: #101010;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: sans-serif;
}
.Hed-text {
  color: #101111;
  font-size: 2rem;
  font-weight: 600;
  font-family: sans-serif;
  cursor: pointer;
}
.Hed-text:hover {
  color: #6b6d6e;
  /* font-size: 1.5rem; */

  font-weight: 600;
  font-family: sans-serif;
}

.wrap {
  width: 75%;
  margin: 25px auto;
}
.post {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  border-bottom: 1px solid #ccc;
  padding: 1.5em 0;
}
.post a {
  color: teal;
  text-decoration: none;
}
.post a:hover {
  color: #00b3b3;
}
.post .feat-img {
  /* Landscape phones and down */
  width: 24%;
  float: left;
  margin-right: 2%;
}
@media (max-width: 480px) {
  .post .feat-img {
    width: 100%;
  }
  .post .feat-img img {
    padding-bottom: 2%;
    display: block;
    margin: auto auto;
    text-align: center;
  }
}
.post .feat-img img {
  max-width: 100%;
  height: 200px;
  object-fit: contain;
}
.post article {
  /* Landscape phones and down */
  width: 74%;
  float: left;
}
@media (max-width: 480px) {
  .post article {
    width: 100%;
  }
}
.post article header p.author-cred {
  background: #f1f1f1;
  padding: 0.5em;
}
.post article header ul.cat {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 480px) {
  .post article header ul.cat {
    display: block;
    margin: auto auto;
  }
}
.post article header ul.cat li {
  color: #ccc;
  display: inline-block;
  padding: 0px 5px 0px 0px;
}
.post article header ul.cat li:last-child:after {
  padding-left: 5px;
  content: none;
}
.post article header ul.cat li:after {
  padding-left: 5px;
  content: " / ";
}
.post article header ul.cat li a {
  color: teal;
  text-decoration: none;
}
.post article header ul.cat li a:hover {
  color: #00b3b3;
}
.News-h {
  color: teal;
  text-decoration: none;
}
.News-h:hover {
  color: #00b3b3;
}
.post h2 {
  font-size: 175%;
  line-height: 1.5em;
  margin: 0.5em 0;
}
.post h2 a {
  color: #333;
}
.post footer {
  width: 100%;
  overflow: auto;
}
ul.tags {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tags li {
  display: block;
  margin-top: 2%;
}
ul.tags li:first-child {
  margin-top: 0;
}
ul.tags li a {
  color: #fff !important;
  padding: 0.3em 0.65em;
  background: teal;
  display: block;
}
ul.tags li a:hover {
  background: #003334;
}
.more-link {
  text-align: center;
  background: teal;
  color: #fff !important;
  padding: 0.5em 0.75em;
  display: inline-block;
  float: right;
}
@media (max-width: 480px) {
  .more-link {
    width: 100%;
    text-align: center;
  }
}
.more-link:hover {
  background: #004d4d;
}

/* ========== News Details =========== */

.cs-blog {
  margin-bottom: 30px;
}
.cs-blog h2 {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 29px;
  margin: 0 0 11px;
  position: relative;
  text-transform: uppercase;
}
.cs-blog::after {
  clear: both;
  content: "";
  display: block;
}
ul.blog-list {
  list-style: outside none none;
  margin: -30px 0 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.blog-list.blog-slide {
  margin: 0;
}
.blog-list.blog-slider {
  margin: 0;
}
ul.blog-list li {
  float: left;
  list-style: outside none none;
  margin: 30px 0 0;
}
.blog-slide .slick-list {
  margin: 0 -15px;
}
ul.blog-list.blog-slide li {
  margin-bottom: 10px;
  margin-top: 0;
}
ul.blog-list li:first-child {
  border: 0 none;
}
ul.blog-list li figure {
  overflow: hidden;
  position: relative;
}
ul.blog-list li figure img {
  width: 100%;
}
ul.blog-list li .cs-text {
  border: 1px solid #f0f0f0;
  overflow: hidden;
  padding: 15px 20px;
}

.cs-blog-detail .cs-text .post-option {
  border-top: 1px solid #f0f0f0;
  float: left;
  padding-top: 10px;
  width: 100%;
}
.cs-blog-detail .cs-text .post-option span a {
  color: #777;
}
.widget ul.blog-list li .cs-text {
  height: auto;
  margin: 0;
  min-height: inherit;
  padding: 9px 0 13px;
}
ul.blog-list li .cs-text span {
  color: #8b919e;
  display: inline-block;
  font-size: 12px;
  line-height: 19px;
}
ul.blog-list li .cs-text p {
  margin-bottom: 12px;
}
ul.blog-list li .cs-text h5 {
  border-bottom: 1px solid #fff;
  font-size: 15px;
  margin: 0;
  min-height: 56px;
  padding: 0 0 5px;
}
ul.blog-list li .cs-text h5 a {
  color: #292c33;
}
ul.blog-list li .cs-text .readmore {
  float: right;
  font-size: 11px;
  line-height: 20px;
  padding-top: 6px;
  position: relative;
  text-transform: uppercase;
}
ul.blog-list .slick-list.draggable {
  overflow: hidden;
}
.cs-auther-name a {
  color: #999;
}
.blog-list .slick-arrow {
  background-color: #f9f9f9;
  float: left;
  height: 29px;
  margin: 5px 0 0 5px;
  text-align: center;
  width: 29px;
}
.blog-list .slick-arrow a {
  color: #999;
  font-size: 18px;
  line-height: 32px;
}
.cs-blog.classic {
  margin: 0 0 30px;
}
.cs-blog.classic ul {
  margin: 0;
}
.cs-blog.classic li {
  border-top: 2px solid #eceef0;
  float: left;
  list-style: outside none none;
  padding: 16px 0;
  width: 100%;
}
.cs-blog.classic p {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 100%;
}
.cs-blog.classic p i {
  color: #c4c6c8;
  margin: 0 10px 0 0;
  vertical-align: middle;
}
.cs-blog.classic span {
  display: inline-block;
  float: right;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
}
.cs-blog.classic span i {
  color: #e2e5e8;
  float: right;
  font-size: 24px;
  margin: 2px 0 0 10px;
}
.cs-pagination-blog {
  margin-bottom: 30px;
}
.cs-blog.blog-medium {
  border-bottom: 0 none;
  margin: 0;
  padding-bottom: 30px;
}
.cs-blog.blog-medium::after {
  clear: both;
  content: "";
  display: block;
}
.cs-blog.blog-medium .blog-text .cs-post-title {
  clear: both;
}
.cs-blog .cs-media figure {
  position: relative;
}
.cs-blog .cs-media figure figcaption {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
}
.cs-blog .cs-media:hover figure figcaption {
  opacity: 1;
  visibility: visible;
}
.cs-blog.blog-medium .post-title h3 {
  margin-bottom: 0;
}
.cs-blog .post-title {
  margin-bottom: 10px;
}
.cs-blog.blog-medium .cs-media figure figcaption .cs-readmore a {
  color: #fff;
  font-size: 24px;
  left: 50%;
  margin: -10px 0 0 -65px;
  position: absolute;
  top: 50%;
  transform: scale(0.7);
}
.cs-blog.blog-medium .cs-media:hover figure figcaption .cs-readmore a {
  transform: scale(1);
}
.cs-blog.blog-medium:last-child {
  border-bottom: medium none;
  padding-bottom: 40px;
}
.blog-medium .cs-media {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  width: 37%;
}
.blog-modern .cs-media {
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;
  width: 48.6%;
}
.blog-medium .cs-media figure img,
.blog-modern .cs-media img {
  width: 100%;
}
.blog-medium .cs-media ~ .blog-text {
  display: inline-block;
  float: none;
  margin-right: 0;
  vertical-align: middle;
  width: 58%;
}
.blog-modern .blog-text {
  display: inline-block;
  margin-right: -4px;
  padding-left: 30px;
  vertical-align: middle;
  width: 51.4%;
}
.blog-modern .blog-text .cs-post-title {
  margin-bottom: 5px;
  padding-bottom: 1px;
  position: relative;
}
.blog-modern .blog-text .cs-post-title::after {
  bottom: 1px;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 27px;
}
.blog-modern .blog-text .blog-separator {
  margin: 0 0 10px;
}
.blog-modern .blog-text .blog-separator::before {
  display: none;
}
.blog-medium .blog-text {
  width: 99.1%;
}
.blog-medium .blog-text p {
  display: inline;
  margin: 0 0 15px;
}
.blog-medium .blog-separator {
  margin: 0 0 10px;
}
.cs-blog .cs-categories,
.cs-blog-detail .cs-categories {
  display: block;
  margin: 0 0 12px;
}
.cs-blog .cs-categories a,
.cs-blog-detail .cs-categories a {
  border-bottom: 2px solid #ededed;
  color: #55a747;
  display: inline-block;
  font-size: 10px;
  margin-right: 5px;
  padding-bottom: 2px;
  text-transform: uppercase;
}
.cs-blog-detail .post-option {
  float: right;
}
.cs-blog .post-option span a,
.cs-blog-detail .post-option span a {
  color: #999 !important;
  display: inline-block;
  font-size: 12px;
  margin-right: 18px;
  vertical-align: middle;
}
.cs-blog .post-option span i,
.cs-blog-detail .post-option span i {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  vertical-align: middle;
}
.cs-blog-detail .post-option span.post-category i {
  margin: 0;
}
.cs-blog-detail .post-option .post-category a {
  margin-left: 10px;
  margin-right: 0;
}
.cs-blog-detail .post-option .post-date {
  margin-left: 18px;
}
.cs-blog-detail .cs-text .post-option span i {
  float: left;
  margin: 3px 8px 0 0;
}
.cs-blog.blog-grid figure img {
  width: 100%;
}
.cs-blog.blog-grid .cs-media ~ .blog-text {
  margin: -30px 0 0;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}
.cs-blog.blog-grid .cs-inner-bolg {
  background-color: #fff;
  display: inline-block;
  padding: 20px 25px;
  width: 100%;
}
.cs-blog.blog-grid .blog-text p {
  margin: 0 0 5px;
}
.cs-blog.blog-grid .post-option {
  line-height: normal;
  margin: 0 0 10px;
}
.cs-blog.blog-grid .post-option span {
  color: #8b919e;
  font-size: 10px;
  margin: 0 15px 0 0;
  position: relative;
  text-transform: uppercase;
}
.cs-blog.blog-grid .post-option span::before {
  background-color: #8b919e;
  border-radius: 100%;
  content: "";
  height: 3px;
  left: -10px;
  position: absolute;
  top: 5px;
  width: 3px;
}
.cs-blog.blog-grid .post-option span:last-child {
  margin: 0;
}
.cs-blog.blog-grid .post-option span:first-child::before {
  display: none;
}
.cs-blog.blog-grid .read-more {
  display: inline-block;
  font-size: 12px;
  position: relative;
}
.cs-blog.blog-grid .read-more::before {
  content: "";
  font-family: "icomoon";
  font-size: 14px;
  position: absolute;
  right: -15px;
  top: 0;
}
.blog-large .cs-media img {
  width: 100%;
}
.blog-large .cs-text {
  margin: 0 0 20px;
  position: relative;
  z-index: 1;
}
.blog-large .cs-media ~ .cs-text {
  background-color: #fff;
  margin: 0 auto;
  padding: 30px 0 0;
  width: 100%;
}
.cs-blog .cs-author,
.cs-blog-detail .cs-author {
  float: left;
  margin: 0 0 10px;
}
.cs-blog .cs-author figure,
.cs-blog-detail .cs-author figure {
  display: inline-block;
  height: 32px;
  margin: 0 10px 0 0;
  vertical-align: middle;
  width: 32px;
}
.cs-blog .cs-author figure img,
.cs-blog-detail .cs-author figure img {
  border-radius: 100%;
}
.cs-blog .cs-author .cs-text,
.cs-blog-detail .cs-author .cs-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.cs-blog .cs-author .cs-text a,
.cs-blog-detail .cs-author .cs-text a {
  color: #555;
  font-size: 13px;
}
.blog-large .post-option,
.cs-blog.blog-medium .post-option {
  float: right;
}
.cs-blog.blog-large .post-option span i,
.cs-blog.blog-medium .post-option span i {
  color: #cfcfcf;
}
.post-option span i {
  margin-right: 5px;
  transition: all 0.3s ease-in-out 0s;
}
.blog-separator {
  border-bottom: 1px solid #f1f1f1;
  display: inline-block;
  margin: 20px 0 25px;
  position: relative;
  width: 100%;
}
.blog-large .cs-text p {
  margin: 0 0 25px;
}
.blog-large .read-more {
  border: 1px solid;
  border-radius: 20px;
  display: inline-block;
  font-size: 12px;
  padding: 4px 20px;
  text-transform: uppercase;
}
.blog-large .cs-post-title {
  margin: 0 0 15px;
}
.blog-large .cs-post-title h3 {
  margin: 0;
}

.cs-blog-detail .cs-post-title h1 {
  margin: 0 0 10px;
}
.cs-blog-detail .cs-post-title::after {
  clear: both;
  content: "";
  display: block;
}
.cs-blog-detail .cs-main-post img {
  width: 100%;
}
.cs-blog-detail .cs-main-post {
  margin-bottom: 25px;
}
.cs-blog-detail .cs-admin-post .cs-media figure,
.cs-blog-detail .cs-admin-post .cs-media figure img {
  border-radius: 100%;
}
.cs-blog-detail .cs-admin-post .cs-text {
  overflow: hidden;
}
.cs-blog-detail .cs-admin-post {
  float: left;
  width: 40%;
}
.cs-blog-detail .cs-admin-post .cs-media {
  float: left;
  height: 46px;
  margin-right: 14px;
  width: 46px;
}
.cs-blog-detail .cs-author-name {
  color: #ccc;
  display: inline-block;
  font-size: 14px;
  margin-right: 20px;
  padding-top: 6px;
  vertical-align: middle;
}
.cs-blog-detail .cs-author-name strong {
  color: #55a747;
  display: block;
  line-height: 26px;
}
.cs-blog-detail .cs-more-post {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #ccc;
  font-size: 11px;
  padding: 6px 8px;
}
.cs-blog-detail .cs-social-share {
  float: right;
}
.cs-blog-detail .cs-social-media {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.cs-blog-detail .cs-social-media li {
  display: inline-block;
  list-style: outside none none;
  margin: 0;
  vertical-align: top;
}
.cs-blog-detail .cs-social-media li a {
  background-color: #fc6d4c;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 13px;
  height: 28px;
  line-height: 30px;
  margin: 0 5px 5px 0;
  text-align: center;
  width: 28px;
}
.cs-blog-detail .cs-social-media li a.cs-more {
  line-height: 33px;
  padding: 0;
}
.cs-blog-detail .cs-social-media li a::before {
  display: none;
}
.cs-blog-detail .cs-social-media li a[data-original-title="facebook"] {
  background-color: #2b4a8b;
}
.cs-blog-detail .cs-social-media li a[data-original-title="Facebook"] {
  background-color: #2b4a8b;
}
.cs-blog-detail .cs-social-media li a[data-original-title="Tumblr"] {
  background-color: #32506d;
}
.cs-blog-detail .cs-social-media li a[data-original-title="tumblr"] {
  background-color: #32506d;
}
.cs-blog-detail .cs-social-media li a[data-original-title="Dribbble"] {
  background-color: #ea4c89;
}
.cs-blog-detail .cs-social-media li a[data-original-title="dribbble"] {
  background-color: #ea4c89;
}
.cs-blog-detail .cs-social-media li a[data-original-title="stumbleupon"] {
  background-color: #eb4823;
}
.cs-blog-detail .cs-social-media li a[data-original-title="Stumbleupon"] {
  background-color: #eb4823;
}
.cs-blog-detail .cs-social-media li a[data-original-title="rss"] {
  background-color: #f06c19;
}
.cs-blog-detail .cs-social-media li a[data-original-title="twitter"] {
  background-color: #1f94d9;
}
.cs-blog-detail .cs-social-media li a[data-original-title="linkedin"] {
  background-color: #10598c;
}
.cs-blog-detail .cs-social-media li a[data-original-title="google"] {
  background-color: #d83936;
}
.cs-blog-detail .cs-social-media li a[data-original-title="youtube"] {
  background-color: #b00;
}
.cs-blog-detail .cs-social-media li a[data-original-title="Youtube"] {
  background-color: #b00;
}
.cs-blog-detail .cs-social-media li a.cs-more .at4-icon {
  border-radius: 10px;
  margin: 5px 0 0 -2px;
}
.cs-blog-detail .cs-share {
  float: none;
  left: 0;
  margin: 0 15px 0 0;
  position: absolute;
  top: 0;
}
.cs-blog-detail .cs-share a {
  color: #333;
  font-size: 18px;
  font-weight: 700;
}
.cs-blog-detail .cs-share-detail::after {
  clear: both;
  content: "";
  display: block;
}
.cs-blog-detail .cs-share-detail {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  vertical-align: middle;
  width: 49%;
}
.cs-blog-detail .cs-post-option-panel {
  float: left;
  padding-top: 20px;
  width: 100%;
}
.cs-blog-detail .rich-editor-text p {
  margin-bottom: 30px;
}
blockquote,
.rich-text-editor blockquote {
  border-left: 4px solid;
  margin: 0 0 40px;
  padding: 20px 0 0;
  position: relative;
  width: 95%;
}
blockquote {
  background-color: #fcfcfc;
  font-style: italic;
  padding: 15px 40px 20px 50px !important;
}
blockquote,
blockquote span,
blockquote p {
  color: #777;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}
blockquote .author-name a {
  color: #999;
  font-size: 11px;
}
blockquote.text-left-align {
  text-align: left;
}
blockquote.text-right-align {
  text-align: right;
}
blockquote.text-center-align {
  text-align: center;
}
blockquote::before,
.rich-text-editor blockquote::before {
  color: #eaeaea;
  content: "";
  font-family: "icomoon";
  font-size: 22px;
  font-style: normal;
  left: 24px;
  position: absolute;
  top: 15px;
  transform: scale(-1);
}
.rich-text-editor blockquote {
  background-color: #fcfcfc;
  font-style: italic;
  padding: 15px 40px 20px 50px;
}
.rich-text-editor blockquote p {
  margin: 0;
}
blockquote > span {
  margin: 0;
  position: relative;
}
blockquote > span.author-name::after {
  display: none;
}
blockquote > span::after {
  color: #eaeaea;
  content: "";
  display: inline-block;
  font-family: "icomoon";
  font-size: 22px;
  font-style: normal;
  margin: 0 0 0 8px;
  position: relative;
  top: 3px;
}
.cs-blog-detail .tags {
  display: inline-block;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 50%;
}
.cs-blog-detail .cs-tags {
  display: block;
  margin: 0 0 40px;
}
.cs-blog-detail .cs-tags .tags span {
  color: #333;
  display: inline-block;
  font-size: 18px;
  margin: 0 10px 5px 0;
}
.cs-blog-detail .cs-tags .tags ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.cs-tags ul li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0 6px;
}
.cs-tags ul li a {
  display: block;
  font-size: 12px;
  margin: 0 8px 0 0;
}
.cs-tags .tags ul li a {
  background-color: #f5f5f5;
  border-radius: 20px;
  color: #777;
  padding: 2px 18px 3px;
}
.comment-respond {
  margin-bottom: 30px;
}
.comment-form ul {
  list-style: outside none none;
}
.comment-form ul li {
  margin-bottom: 30px;
}
.comment-form .cs-element-title h3 {
  margin: 0;
}
.comment-form form .input-holder {
  position: relative;
}
.comment-form form .input-holder i {
  color: #cecece;
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 15px;
}
.comment-form form .input-holder input[type="text"],
.comment-form form .input-holder textarea {
  border: 1px solid #e4e4e4;
  color: #999;
  font-size: 14px;
  height: 50px;
  margin-bottom: -1px;
  padding: 10px 20px;
  width: 100%;
}
.comment-form form .input-holder textarea {
  height: 214px;
  margin: 0 0 20px;
}
.comment-form form input[type="submit"] {
  background-color: #55a747;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 10px 30px;
  text-transform: uppercase;
}
.blog-detail {
  box-shadow: none;
}
.blog-detail .blog-list {
  float: left;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}
.blog-slider-next {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 10px;
}
.blog-slider-prev {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
}
.blog-detail::after,
.author-detail::after,
#comment ul li::after,
.blog-detail .blog-list::after,
.cs-packeges::after {
  clear: both;
  content: "";
  display: block;
}
.blog-title {
  margin-bottom: 25px;
}
.blog-title h3 {
  color: #282828;
  letter-spacing: -1px;
  line-height: 34px;
  margin: 0 0 10px;
}
.blog-detail .main-post {
  margin: 0 0 25px;
}
.blog-detail .main-post img {
  width: 100%;
}
.author-detail {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 22px;
}
.cs-admin figure {
  float: left;
  margin-right: 15px;
}
.cs-admin .cs-text {
  display: inline-block;
  overflow: hidden;
  padding-top: 8px;
}
.cs-admin .cs-text span {
  color: #ccc;
  display: block;
  font-size: 13px;
  line-height: 16px;
}
.cs-admin .cs-text strong {
  color: #282828;
  font-size: 14px;
  line-height: 18px;
}
.blog-detail h2 {
  line-height: 29px;
  margin: 0 0 11px;
  position: relative;
  width: 91%;
}
.rich-editor-text p {
  clear: both;
  line-height: 24px;
  margin-bottom: 20px;
}

/* ================== News end ================= */

/* ================== Spinner  ================= */
/* Spinner.css */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: relative;
  width: 100px;
  height: 100px;
}

.spinner-inner {
  position: absolute;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333; /* Spinner color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.text {
  position: absolute;
  /* top: 50%; */
  left: 20%;
  /* transform: translate(-50%, -50%); */
  font-size: 16px;
  color: #333; /* Text color */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* ================== Spinner end ================= */

/* ================== Compare  ================= */

.fade-com {
  z-index: 6;
  transition-duration: 0ms;
  display: block;
  opacity: 0.6;
}

.compare-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #fcfcfc;
  z-index: 1000;
}

.popup-content {
  max-height: 400px; /* Adjust as needed */
  overflow-y: hidden;
}

.vs {
  font-size: 24px; /* adjust as needed */
  padding: 10px;
}
.Heading-com {
  color: #0a6075;
  font-size: 1.5rem;
  font-weight: bold;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.remove {
  cursor: pointer;
  margin-left: 10px;
  color: red;
}

/* ComparisonPage.css */
.comparison-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.comparison-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  width: 100%; /* Adjust the width to fit four items side by side */
  box-sizing: border-box;
}

.comparison-item img {
  height: 150px; /* Set a fixed height for the images */
  width: 100%; /* Make images responsive within their container */
}

.specifications {
  margin-top: 10px;
}

.compare-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(254, 254, 254);
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 1000;
}

.popup-content {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* color: red; */
}

.remove {
  cursor: pointer;
  margin-left: 10px;
  color: red;
}

/* ComparisonPage.css */
.comparison-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.comparison-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  width: 80px; /* Adjust the width to fit four items side by side */
  box-sizing: border-box;
}

.comparison-item img {
  height: 150px; /* Set a fixed height for the images */
  width: 100%; /* Make images responsive within their container */
}

.comp-btn {
  padding: 10px !important;
  background-color: #0a6075 !important;
  color: #fff !important;
  font-size: 15px;
  border-radius: 10px;
}

.specifications {
  margin-top: 10px;
}

.add-product-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 2;
}

.search-results {
  margin-top: 20px;
}

.add-product-popup {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.popup-content {
  padding: 16px;
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  /* color: red; */
}

.search-main {
  overflow-y: auto;
  max-height: 200px;
}

.search-result-item {
  transition: background-color 0.3s ease-in-out;
}

.selected-item {
  background-color: #f0f0f0;
}

.comparison-popup {
  position: fixed;
  bottom: 0;
  /* margin-left: 200px; */
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 15px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
}

.popup-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.close {
  color: rgb(255, 255, 255);
  right: 40px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  color: red;
  top: 25px;
}

.close:hover,
.close:focus {
  color: rgb(122, 9, 9);
  text-decoration: none;
  cursor: pointer;
}

.comparison-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: auto;
}

.comparison-list li {
  text-align: center;
}

.remove {
  position: relative;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust the font size as needed */
  color: red; /* Change the color as needed */
}

.remove:hover {
  color: darkred;
}
.empty-item {
  font-size: 56px;
  cursor: pointer;
  padding: 3rem;
}
.card {
  /* box-shadow: none !important;
  width: min-content;
  width: 190px !important; */
}
.item-name {
  font-size: 13px;
}
.comparison-image {
  height: 10vh !important;
  object-fit: contain;
}
.com-btn {
  padding: 10px;
  font-size: 16px;
  background-color: #2aa0bd;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 60px;
}
.compare-search {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 57vw;
  margin-left: 22vw;
  height: 40vh;
  filter: drop-shadow(2px 4px 6px black);
}
.compare-search-text {
  width: 54vw;
  padding: 10px;
  margin-bottom: 8px;
  border: none;
  box-shadow: 2px 2px 9px gray;
  border-radius: 5px;
  margin-left: 1vw;
  font-size: 1rem;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .empty-item {
    font-size: 20px;
    cursor: pointer;
    padding: 1rem;
  }
  .item-name {
    font-size: 5px;
  }
  .comparison-image {
    height: 5vh !important;
  }
  .comparison-item {
    border: 1px solid #ccc;
    padding: 1;
    margin: -6px;
    width: 80px;
    box-sizing: border-box;
    height: 157px;
  }
  .com-btn {
    margin-top: 10px;
  }
  .card {
    /* width: 60px !important; */
  }
  .popup-content {
    max-height: 300px; /* Adjust as needed */
    overflow-y: auto;
  }
}

.comparison-item {
  width: 150px; /* Adjust based on your design */
  height: 200px; /* Adjust based on your design */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide overflow content */
  position: relative; /* For positioning the remove button */
  background-color: #fff;
}

.comparison-item .item-name {
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%; /* Adjust as needed */
  text-align: center; /* Center text */
  word-wrap: break-word;
  white-space: normal;
}

.empty-item {
  width: 150px; /* Same as .comparison-item */
  height: 200px; /* Same as .comparison-item */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc; /* Example style */
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  border: 1px solid #dddddd;
  padding: 12px;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding-top: 16px;
  padding-bottom: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td:last-child {
  /* text-align: right; */
}

td:empty {
  background-color: #f9f9f9;
}

td:first-child {
  font-weight: bold;
  width: 150px;
}

td:nth-child(2) {
  color: #008cba;
}

td:nth-child(3) {
  color: #d32f2f;
}

td:nth-child(4) {
  color: #1a237e;
}

td:nth-child(5) {
  color: #006064;
}

td:first-child img {
  width: 100px;
  height: auto;
}
td img {
  width: 100px;
  height: auto;
}

@media screen and (max-width: 600px) {
  /* table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  } */

  th,
  td {
    padding: 8px;
  }

  th {
    font-weight: normal;
  }

  td:first-child {
    font-weight: bold;
    width: auto;
  }

  td:last-child {
    text-align: left;
  }

  td:nth-child(2) {
    color: #008cba;
  }

  td:nth-child(3) {
    color: #d32f2f;
  }

  td:nth-child(4) {
    color: #1a237e;
  }

  td:nth-child(5) {
    color: #006064;
  }

  td img {
    width: 100%;
    height: auto;
  }

  td:first-child img {
    width: 100px;
    height: auto;
  }
}

.comparison-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.compare-slot {
  border: 2px dashed #ccc;
  border-radius: 50%;
  width: 120px; /* adjust as needed */
  height: 120px; /* adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.add-icon {
  font-size: 24px; /* adjust as needed */
}

.add-to-compare {
  border: none;
  background: none;
  margin-top: 10px; /* adjust as needed */
}

.vs {
  font-size: 24px; /* adjust as needed */
  /* padding: 10px; */
}

.compare-now {
  background: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-container {
  position: absolute;
  top: 60%; /* Position just below the slot */
  left: 8.5%; /* Start from the middle of the slot */
  transform: translateX(-50%); /* Center it under the slot */
  width: auto; /* Adjust based on content or set a specific width */
  max-width: 300px; /* Maximum width to prevent it from getting too wide */
  background-color: #ffffff; /* Light background for the dropdown */
  border: 1px solid #d1d5db; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 50px; /* Padding around the dropdown content */
  z-index: 1000; /* High z-index to ensure it's above other content */
  display: flex;
  flex-direction: column; /* Align items in a column */
  gap: 8px; /* Space between items */
}

/* Style for items within the dropdown, if they are buttons or links */
.dropdown-container button,
.dropdown-container a {
  padding: 8px 12px;
  text-align: left; /* Align text to the left for better readability */
  background-color: #f9fafb; /* Slightly off-white for contrast */
  border: 1px solid #e5e7eb; /* Very light grey border */
  border-radius: 6px; /* Slightly rounded corners for the items */
  color: #1f2937; /* Dark grey text for readability */
  text-decoration: none; /* Remove underline from links */
  cursor: pointer; /* Indicate clickable items */
}

/* Hover effect for items to indicate interactivity */
.dropdown-container button:hover,
.dropdown-container a:hover {
  background-color: #e5e7eb; /* Slightly darker on hover */
}

/* ================== Compare end ================= */

/* ================== FAqs end ================= */
.faq-list {
  list-style: none;
  padding: 0;
}

.faq-list li {
  margin: 30px 0;
  border: 1px solid rgb(205, 205, 205);
  padding: 27px 30px;
  margin: 0px auto 30px;
  text-align: left;
  width: 550px;
}

.faq-list .faq-heading::before {
  content: "+";
  font-size: 40px;
  display: block;
  position: absolute;
  right: 0;
  top: -8px;
  color: #c2c2c2;
}

.faq-list .the-active .faq-heading::before {
  content: "-";
}

.faq-heading {
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.faq-heading:hover {
  color: var(--theme-color);
}

.faq-text {
  display: none;
}
.container {
  width: 1200px;
  margin: auto;
}
.art-box svg {
  width: 100%;
}
.row {
  display: flex;
}
.row .col {
  flex-basis: 50%;
}
.read {
  color: rgb(100, 100, 100);
  font-size: 16px;
  line-height: 1.5;
  margin-top: 25px;
}

/* ================== FAqs end ================= */

/* ================== Product-Details  ================= */
.dtl-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.dtl-table caption {
  /* font-size: 1.5em; */
  /* margin: 0.5em 0 0.75em; */
}

.dtl-table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

.dtl-table th,
.dtl-table td {
  padding: 0.625em;
  /* text-align: center; */
}

.dtl-table th {
  /* font-size: 0.85em; */
  /* letter-spacing: 0.1em; */
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .dtl-table {
    border: 0;
  }

  .dtl-table caption {
    /* font-size: 1.3em; */
  }

  .dtl-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .dtl-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .dtl-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    /* text-align: right; */
  }

  .dtl-table td::before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    /* float: left; */
    font-weight: bold;
    text-transform: uppercase;
  }

  .dtl-table td:last-child {
    border-bottom: 0;
  }
}

.hello {
  white-space: normal;
  overflow-wrap: break-word;
}

/* ================== Product-Details end ================= */

/* ================== Wishlist  ================= */
.wishlist {
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  margin-top: 50px;
  /* height: 400px;
  width: 300px; */
}
@media (max-width: 600px) {
  .wishlist {
    display: grid;
    grid-template-columns: repeat(2, minmax(190px, 1fr));
    grid-gap: 10px;
    justify-content: center;
    margin-top: 50px;
  }
}

.wishlist-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.wishlist-item:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.wishlist-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.wishlist-item h3 {
  margin-bottom: 10px;
}

.wishlist-item p {
  margin-bottom: 20px;
}

.wishlist-item button {
  padding: 10px 20px;
  background-color: #0a6075;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.wishlist-item button:hover {
  background-color: #3ea4bd;
}

.wishlist-item button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* not item in wishlist  */

.no-items-message {
  font-size: 20px;
  color: #555;
  text-align: center;
  margin-top: 20px;
}

.button-no-items-message {
  background-color: #4caf50; /* Green */
  border: none;
  color: rgb(243, 239, 239);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.button-no-items-message:hover {
  background-color: #45a049; /* Darker Green */
  color: white;
  text-decoration: none;
}

/* ================== Wishlist end ================= */

/* ================== Comparision Page ============== */

.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
}
@media (min-width: 48em) {
  .responsive-table {
    font-size: 0.9em;
  }
}
@media (min-width: 62em) {
  .responsive-table {
    font-size: 1em;
  }
}
.responsive-table thead {
  /* position: absolute;
  clip: rect(1px 1px 1px 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden; */
}
@media (min-width: 48em) {
  .responsive-table thead {
    position: relative;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
}
.responsive-table thead th {
  /* background-color: #26890d;
  border: 1px solid #86bc25; */
  /* font-weight: normal;
  text-align: center;
  color: white; */
  display: table-cell;
}
.responsive-table thead th:first-of-type {
  /* text-align: left; */
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  /* text-align: left; */
  white-space: normal;
}
@media (min-width: 48em) {
  .responsive-table tr {
    display: table-row;
  }
}
.responsive-table th,
.responsive-table td {
  padding: 0.5em;
  vertical-align: middle;
}
@media (min-width: 30em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em 0.5em;
  }
}
@media (min-width: 48em) {
  .responsive-table th,
  .responsive-table td {
    display: table-cell;
    padding: 0.5em;
  }
}
@media (min-width: 62em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em 0.5em;
  }
}
@media (min-width: 75em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em;
  }
}
.responsive-table caption {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 48em) {
  .responsive-table caption {
    font-size: 1.5em;
  }
}
.responsive-table tfoot {
  font-size: 0.8em;
  font-style: italic;
}
@media (min-width: 62em) {
  .responsive-table tfoot {
    font-size: 0.9em;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody {
    display: table-row-group;
  }
}
.responsive-table tbody tr {
  margin-bottom: 1em;
}
@media (min-width: 48em) {
  .responsive-table tbody tr {
    display: table-row;
    border-width: 1px;
  }
}
.responsive-table tbody tr:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .responsive-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.responsive-table tbody th[scope="row"] {
  background-color: #26890d;
  color: white;
}
@media (min-width: 30em) {
  .responsive-table tbody th[scope="row"] {
    border-left: 1px solid #86bc25;
    border-bottom: 1px solid #86bc25;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody th[scope="row"] {
    background-color: transparent;
    color: #000001;
    /* text-align: left; */
  }
}
.responsive-table tbody td {
  /* text-align: right; */
}
@media (min-width: 48em) {
  .responsive-table tbody td {
    border-left: 1px solid #86bc25;
    border-bottom: 1px solid #86bc25;
    text-align: center;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody td:last-of-type {
    border-right: 1px solid #86bc25;
  }
}
.responsive-table tbody td[data-type="currency"] {
  text-align: right;
}
.responsive-table tbody td[data-title]:before {
  content: attr(data-title);
  float: left;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.54);
}
@media (min-width: 30em) {
  .responsive-table tbody td[data-title]:before {
    font-size: 0.9em;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody td[data-title]:before {
    content: none;
  }
}

.image-table {
  border: 0px solid rgba(0, 0, 0, 0);
  border-collapse: separate;
  border-spacing: 6px;
  table-layout: fixed;
  text-align: center;
  width: 100%;
}

.image-table img {
  border: 10px solid #fff;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
}

/* ================== Comparision Page end ============== */

/* ================== new Comparision popup   ============== */

.compare-popup {
  position: relative;
  background: #fff;
  padding: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: auto;
  max-width: 1200px;
  /* margin: 20px auto; */
  z-index: 0;
  text-align: center;
  top: 433px; /* Center the contents horizontally */
}

.product-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.product-card {
  border: 1px solid #ddd;
  padding: 10px;
  flex: 0 1 calc(25% - 20px);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative; /* For positioning the close button */
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.product-details {
  margin: 10px 0;
}

.add-product-btn,
.compare-btn {
  padding: 10px 20px;
  /* background-color: #0044cc;
  color: white; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-btn {
  background-color: transparent;
  color: #ff4444;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.25em; /* Larger font size for the X */
}

.remove-btn:before {
  content: "×"; /* Unicode multiplication sign */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-card {
    flex: 0 1 calc(50% - 20px);
  }
}

.product-placeholder {
  border: 1px dashed #ccc;
  padding: 10px;
  flex: 0 1 calc(25% - 20px);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-icon {
  font-size: 2em; /* Large plus icon */
  color: #ccc;
}

.add-text {
  color: #ccc;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-placeholder,
  .product-card {
    flex: 0 1 calc(50% - 20px);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust spacing between buttons and product cards */
}

.add-product-btn,
.compare-btn {
  padding: 10px 10px;
  background-color: #0a6075;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 10px; /* Adjust spacing between buttons */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
  }

  .add-product-btn,
  .compare-btn {
    margin: 10px; /* Add spacing around buttons */
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 650px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  position: relative;
  z-index: 1000;
  height: 500px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* ================== new Comparision popup  end ============== */

/* ================== blog   ============== */

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
}
.blog-card a {
  color: inherit;
}
.blog-card a:hover {
  color: #5ad67d;
}
.blog-card:hover .photo {
  transform: scale(1.3) rotate(3deg);
}
.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}
.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}
.blog-card .details a {
  -webkit-text-decoration: dotted underline;
  text-decoration: dotted underline;
}
.blog-card .details ul li {
  display: inline-block;
}
.blog-card .details .author:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "";
}
.blog-card .details .date:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "";
}
.blog-card .details .tags ul:before {
  font-family: FontAwesome;
  content: "";
  margin-right: 10px;
}
.blog-card .details .tags li {
  margin-right: 2px;
}
.blog-card .details .tags li:first-child {
  margin-left: -4px;
}
.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}
.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}
.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}
.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}
.blog-card .description .read-more {
  text-align: right;
}
.blog-card .description .read-more a {
  color: #5ad67d;
  display: inline-block;
  position: relative;
}
.blog-card .description .read-more a:after {
  content: "";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}
.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}
.blog-card p:first-of-type {
  margin-top: 1.25rem;
}
.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #5ad67d;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}
.blog-card:hover .details {
  left: 0%;
}
@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 700px;
  }
  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .blog-card.alt {
    flex-direction: row-reverse;
  }
  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }
  .blog-card.alt .details {
    padding-left: 25px;
  }
}

/* ================== blog  end ============== */
.list-group-item {
  color: #0a6075;
  /* padding: 10px; */
}
.list-group-item:hover {
  color: #0f9ec1;
  text-decoration: underline;
  cursor: pointer;
}
.tspage {
  overflow-y: scroll;
  height: 40vw;
}
.ts-Hed {
  font-size: 1.5rem;
  color: #fff;
  background-color: #0a6075;
  border-radius: 10px 10px 0px 0px;
}
.ts-list {
  list-style-type: disc;
}
.ts-image {
  width: 30%;
}
.ts-product {
  font-size: 2rem;
  font-weight: 600;

  color: #13a1c5;
}
.ts-product:hover {
  font-size: 2rem;
  font-weight: 600;
  color: #045a6f;
}
@media (max-width: 768px) {
  .ts-Hed {
    font-size: 1rem;
    color: #fff;
    background-color: #0a6075;
    border-radius: 10px 10px 0px 0px;
  }
}

/* ================== trending start ============== */

.trending__heading {
  font-size: 30px; /* Large font size for emphasis */
  font-family: "Poppins", sans-serif; /* Modern, clean font */
  color: #333; /* Dark grey for strong visibility */
  text-transform: uppercase; /* Making text uppercase for a more impactful look */
  letter-spacing: 1.5px; /* Spacing out letters slightly */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for 3D effect */
  margin-bottom: 20px; /* Space below the heading */
  padding: 10px 0; /* Padding above and below the text */
  border-bottom: 3px solid #0056b3; /* Solid line under the heading for definition */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
}

.trending__heading:hover {
  color: #0056b3; /* Change color on hover */
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); /* More prominent shadow on hover */
}

/* ======= About ============= */

.highlight {
  color: red; /* Change this to the desired color */
  font-weight: bold; /* Change this to the desired font weight */
}

.gf-datepicker input {
  border: 1px solid #adadad;
  padding: 2px 14px;
  border-radius: 3px;
  margin: 0 4px;
}

.brand-filter-container {
  display: flex;
}

.sidebar {
  width: 250px; /* Adjust width as needed */
  background-color: #f4f4f4; /* Background color for sidebar */
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
}

.sidebar h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar label {
  display: block;
  cursor: pointer;
}

.sidebar input[type="checkbox"] {
  margin-right: 5px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .brand-filter-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%; /* Take full width on smaller screens */
  }
}

.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
}

.filter-container {
  display: flex;
  flex-direction: column;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-group h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.filter-group ul {
  list-style-type: none;
  padding: 0;
}

.filter-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filter-group input[type="checkbox"] {
  margin-right: 5px;
}

.loading,
.error {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
}

.product-compare {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 02px 5px rgba(0, 0, 0, 0.1);
}

.compare-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.compare-count {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.compare-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.compare-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.remove-button {
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #e53935;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.add-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.add-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #45a049;
}

.comparison-content {
  margin: 15px 0 22px;
}
.comparison-content .table-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.comparison-content .table-row.odd .table-item,
.comparison-content .table-row.even .table-item {
  padding: 10px;
}
.comparison-content .table-row.odd .table-item:first-of-type,
.comparison-content .table-row.even .table-item:first-of-type {
  display: inline-block;
}
.comparison-content .table-row:last-child .table-item:empty {
  display: none;
}
.comparison-content .table-row:last-child a {
  text-decoration: none;
  font-size: 12px;
  color: #633a82;
}
.comparison-content.items__number1 .table-item {
  width: 50%;
}
.comparison-content.items__number2 .table-item {
  width: 33.3%;
}
.comparison-content.items__number3 .table-item {
  width: 25%;
}
.comparison-content.items__number4 .table-item {
  width: 20%;
}
.comparison-content.items__number5 .table-item {
  width: 16.6%;
}
.comparison-content .table-item {
  position: relative;
  flex: 1 1 auto;
  background: #fff;
  font-weight: 300;
  font-size: 13px;
  color: #4a4a4a;
  line-height: 16px;
}
.comparison-content .table-item:before {
  content: ".";
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  bottom: -6px;
  box-shadow: -6px 0 6px -6px rgba(155, 155, 155, 0.6),
    6px 0 6px -6px rgba(155, 155, 155, 0.6);
  background: #ffffff;
  font-size: 0;
  z-index: -1;
}
.comparison-content .table-item:first-child {
  display: none;
  flex-grow: 0;
  flex-basis: 100%;
  text-align: center;
  background: #f6f6f6;
  font-weight: normal;
  color: #633a82;
}
.comparison-content .table-item:first-child:empty {
  display: none;
}
.comparison-content .remove-compare {
  display: none;
}
.comparison-content .title-compare {
  padding: 20px 6px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}
.comparison-content .field--name-field-rich-summary {
  display: none;
}
.comparison-content .field--name-field-thumbnail {
  padding: 15px 6px;
}
.comparison-content img {
  max-width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .comparison-content {
    margin: 40px 0;
  }
  .comparison-content .table-row {
    flex-wrap: nowrap;
  }
  .comparison-content .table-row.odd .table-item:first-child,
  .comparison-content .table-row.even .table-item:first-child {
    color: #633a82;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }
  .comparison-content .table-row.odd .table-item:empty,
  .comparison-content .table-row.even .table-item:empty {
    display: inline-block;
  }
  .comparison-content .table-row.odd .table-item {
    background: #f6f6f6;
  }
  .comparison-content .table-row:first-child .table-item {
    padding: 0;
  }
  .comparison-content .table-row:first-child .table-item:before {
    box-shadow: -6px 0 6px -6px rgba(155, 155, 155, 0.6),
      6px 0 6px -6px rgba(155, 155, 155, 0.6),
      0 -6px 6px -6px rgba(155, 155, 155, 0.6);
  }
  .comparison-content .table-row:last-child .table-item {
    padding: 30px 0;
    text-align: center;
  }
  .comparison-content .table-row:last-child .table-item:before {
    bottom: 0;
    box-shadow: -6px 0 6px -6px rgba(155, 155, 155, 0.6),
      6px 0 6px -6px rgba(155, 155, 155, 0.6),
      0 6px 6px -6px rgba(155, 155, 155, 0.6);
  }
  .comparison-content .table-row:last-child .table-item:first-child:before {
    content: none;
  }
  .comparison-content .table-row:last-child a {
    display: inline-block;
    border: 2px solid transparent;
    padding: 10px 16px 10px 16px;
    background: #633a82;
    font-size: 16px;
    color: #fff;
    line-height: 20px;
  }
  .comparison-content .table-row:last-child a:hover {
    border-color: #633a82;
    background: #fff;
    color: #633a82;
  }
  .comparison-content .table-item {
    display: inline-block;
    margin: 0 7px;
    padding: 20px 10px;
    font-size: 16px;
  }
  .comparison-content .table-item:first-child {
    display: inline-block;
    flex-basis: auto;
    background: #fff;
  }
  .comparison-content .table-item:first-child:empty {
    display: inline-block;
  }
  .comparison-content .title-compare {
    padding: 0 16px;
    font-size: 24px;
    font-weight: 300;
    line-height: 28px;
  }
  .comparison-content .remove-compare {
    float: right;
    display: inline-block;
    margin: 8px 10px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(https://www.shareicon.net/data/256x256/2017/06/13/887007_delete_512x512.png);
    background-size: 100%;
    font-size: 0;
  }
  .comparison-content .field--name-field-thumbnail {
    padding: 15px;
  }
  .comparison-content .field--name-field-rich-summary {
    display: block;
    padding: 15px;
  }
  .comparison-content .field--name-field-rich-summary p {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 18px;
  }
  .comparison-content .field--name-field-rich-summary p strong {
    font-size: 24px;
    color: #454545;
    letter-spacing: 0;
    line-height: 28px;
  }
}

.compare-popup1 {
  position: relative;
  background: #fff;
  padding: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: auto;
  max-width: 1200px;
  /* margin: 20px auto; */
  z-index: 0;
  text-align: center;
  top: 430px; /* Center the contents horizontally */
}
.compare-popup {
  position: fixed;
  background: #fff;
  padding: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: auto;
  max-width: 1200px;
  /* margin: 20px auto; */
  z-index: 999;
  text-align: center;
  top: 365px; /* Center the contents horizontally */
}

.product-cards {
  flex: 1 0 0%; /* flex-grow, flex-shrink, and flex-basis */
  margin: 0px; /* Optional: Adjust spacing between cards */
  flex-wrap: wrap;
}

.product-card {
  border: 1px solid #ddd;
  padding: 10px;
  flex: 0 1 calc(25% - 20px);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative; /* For positioning the close button */
}

.product-image {
  width: 10rem;
  height: 10rem;
  border-radius: 4px;
  object-fit: contain;
}

.product-details {
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-product-btn,
.compare-btn {
  padding: 10px; /* background-color: #0044cc;
  color: white; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-btn {
  background-color: transparent;
  color: #ff4444;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.25em; /* Larger font size for the X */
}

.remove-btn:before {
  content: "×"; /* Unicode multiplication sign */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-cards {
    flex: 0 1 calc(10% - 20px);
    margin-top: -23px;
  }
  .compare-popup {
    top: 507px;
  }
}

.product-placeholder {
  border: 1px dashed #ccc;
  padding: 10px;
  flex: 0 1 calc(25% - 20px);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-icon {
  font-size: 2em; /* Large plus icon */
  color: #ccc;
}

.add-text {
  color: #ccc;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-placeholder,
  .product-card {
    flex: 0 1 calc(50% - 20px);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust spacing between buttons and product cards */
}

.add-product-btn,
.compare-btn {
  padding: 10px;
  background-color: #0a6075;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 10px; /* Adjust spacing between buttons */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
  }

  .add-product-btn,
  .compare-btn {
    /* margin: 10px;  */
  }
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  background: #fff;
  padding: 20px;
  width: 90%;
  max-width: 650px;
  top: 25px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  z-index: 1000;
  height: 500px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* .product-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
} */

.product-card {
  flex: 1 0 12%;
  margin: 5px;
}

@media (max-width: 768px) {
  /* Adjustments for mobile devices */
  .product-card,
  .empty-item {
    flex: 1 0 8%; /* Makes each card take roughly half the width of the container */
    width: 42%;
  }
}

.search-suggestion-dropdown {
  position: absolute;
  top: 100%; /* Position it just below the input box */
  left: 0;
  width: 100%;
  max-height: 250px; /* Set a maximum height for the dropdown */
  background-color: #ffffff; /* Set a white background */
  /* z-index: 9999;  Ensure it's on top of other elements */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  border-radius: 4px; /* Optional: Add some border-radius for rounded corners */
  overflow-y: auto; /* Enable vertical scrolling if the content exceeds the height */
}

.search-suggestion-item {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0; /* Add a light separator between items */
  cursor: pointer;
}

.search-suggestion-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.search-suggestion-item:hover {
  background-color: #f0f0f0; /* Add a hover effect */
}

/* Optional: Customize scrollbar appearance for modern browsers */
.search-suggestion-dropdown::-webkit-scrollbar {
  width: 6px;
}

.search-suggestion-dropdown::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.search-suggestion-dropdown::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

/* .modal {
  position: fixed !important;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
} */

.content-container {
  /* Ensure it follows TinyMCE's styling */
  font-family: Arial, sans-serif;
  line-height: 1.6;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.content-container img {
  max-width: 100%; /* Ensure images are responsive */
}

.content-container table {
  width: 100%; /* Ensure tables fit inside the modal */
  border-collapse: collapse;
}

.content-container th,
.content-container td {
  border: 1px solid #ddd;
  padding: 8px;
}

.content-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.content-container ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.content-container h1,
.content-container h2,
.content-container h3 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
/* admin v2 for product add page  */

.adminaddproduct_card-body {
  background-color: #090909 !important;
  padding: 20px !important;
  /* height: 50% !important; */
  color: white;
}

.adminaddproduct_btn {
  color: white !important;
  background-color: rgb(18, 79, 9) !important;
}
.adminaddproduct_btn_delete {
  color: white !important;
  background-color: rgb(168, 55, 32) !important;
}
.adminaddproduct_btn_addproduct {
  color: white !important;
  background-color: rgb(6, 71, 46) !important;
}
.adminaddproduct_btn_addproduct_main {
  color: white !important;
  background-color: #183b05 !important;
}
