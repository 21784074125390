.custom-table-container {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-header {
  background-color: #f3f4f6;
  color: #6b7280;
  text-align: left;
  font-weight: bold;
}

.custom-table-body tr:nth-child(even) {
  background-color: #f3f4f6;
}

.custom-table-body tr:hover {
  background-color: #edf2f7;
}

.custom-table td,
.custom-table th {
  padding: 8px 16px;
}
