.sidebar {
  background-color: #091a2c;
  color: #fff;
  height: 100vh; /* Ensure it covers full viewport height */
  width: auto; /* Width of the sidebar */
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto; /* Enable vertical scrolling if necessary */
  transition: transform 0.3s ease; /* Smooth transition for toggling */
}

/* Adjust sidebar visibility on small screens */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hide sidebar off-screen */
  }
}

.sidebarMenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarMenu li {
  padding: 10px 20px;
}

.sidebarMenu li a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.sidebarMenu li:hover,
.sidebarMenu li.active {
  background-color: #555; /* Hover and active state */
}

/* adminaddnew */
