.Tcards {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  max-width: 1000px;
}

/* Add media query for mobile devices */
@media only screen and (max-width: 768px) {
  .Tcards {
    flex-wrap: wrap;
    /* width: 83.5%; */
  }
  .Tcard {
    /* width: 83.5% !important; */
    margin-right: 0;
    margin-bottom: 20px;
  }
  .Tcard__img {
    height: 150px;
  }
  .Tcard__info {
    padding: 10px 16px 16px 16px;
  }
  .Tcard__title {
    font-size: 16px;
  }
  .Tcard__by {
    font-size: 10px;
  }
  .Tcard__author {
    font-size: 12px;
  }
  .Tcard__img {
    background-size: cover;
    object-fit: contain;
  }
}

/* Add media query for smaller mobile devices */
@media only screen and (max-width: 480px) {
  .Tcards {
    flex-wrap: wrap;
    width: 83.5% !important;
  }
  .Tcard__img {
    height: 100px;
  }
  .Tcard__info {
    padding: 8px 12px 12px 12px;
  }
  .Tcard__title {
    font-size: 14px;
  }
  .Tcard__by {
    font-size: 9px;
  }
  .Tcard__author {
    font-size: 11px;
  }
  .Tcard__img {
    background-size: cover;
    object-fit: contain;
  }
}

.Tcard--1 .Tcard__img,
.Tcard--1 .Tcard__img--hover {
  background-image: url("https://mainprojectimg.s3.amazonaws.com/trending/DALL%C2%B7E-2024-04-29-17.25.jpeg");
}

.Tcard--2 .Tcard__img,
.Tcard--2 .Tcard__img--hover {
  background-image: url("https://mainprojectimg.s3.amazonaws.com/trending/1.png.jpeg");
}
.Tcard--3 .Tcard__img,
.Tcard--3 .Tcard__img--hover {
  background-image: url("https://mainprojectimg.s3.amazonaws.com/trending/2.jpeg");
}

.Tcard__like {
  width: 18px;
}

.Tcard__clock {
  width: 15px;
  vertical-align: middle;
  fill: #ad7d52;
}
.Tcard__time {
  font-size: 12px;
  color: #ad7d52;
  vertical-align: middle;
  margin-left: 5px;
}

.Tcard__img {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.Tcard__info-hover {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.Tcard__img--hover {
  transition: 0.8s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;
}
.Tcard {
  margin-right: 25px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  width: calc(33.3% - 25px);
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .Tcard {
    width: calc(
      30% - 25px
    ); /* adjust the width of each card for small screens */
  }
  .Tcards {
    display: none;
  }
}

@media (min-width: 770px) and (max-width: 1800px) {
  .t-product {
    display: none;
  }
}

@media (max-width: 480px) {
  .Tcard {
    width: 100%; /* make each card full-width on extra small screens */
  }
}
.Tcard:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.1, 1.1);
}

.Tcard__info {
  z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 24px 24px 24px;
}

.Tcard__category {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #868686;
}

.Tcard__title {
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: "Roboto Slab", serif;
}

.Tcard__by {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

.Tcard__author {
  font-weight: 600;
  text-decoration: none;
  color: #ad7d52;
}

.Tcard:hover .Tcard__img--hover {
  height: 100%;
  opacity: 0.3;
}

.Tcard:hover .Tcard__info {
  background-color: transparent;
  position: relative;
}

.Tcard:hover .Tcard__info-hover {
  opacity: 1;
}

.product-card1 {
  display: flex;
  flex-wrap: nowrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.product-image1 {
  flex: 0 0 200px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image1 img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.product-content {
  flex: 1;
}

.product-content h2 {
  font-weight: bold;
  margin-top: 0;
}

.product-content p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.product-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ticon {
  padding: 4px;
  font-size: 12px;
  color: #2cd808;
}

.product-content li {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.product-content li:last-child {
  border-bottom: none;
}
.product-actions {
  text-align: end;
}

.product-actions button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #0a6075;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.product-actions button:hover {
  background-color: #074e60;
}

@media (max-width: 768px) {
  .product-card1 {
    flex-direction: column;
  }

  .product-image1 {
    flex: 0 0 auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .product-content {
    flex: 1;
  }
}
.product-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-score {
  display: flex;
  align-items: center;
  margin-right: 10px;
  background-color: #70bc14;
  padding: 5px;
  border-radius: 4px;
}

.product-score-value {
  font-size: 24px;
  font-weight: bold;
  margin-right: 3px;
  /* background-color: #8cd730; */

  color: #fff;
}

.product-score-label {
  font-size: 20px;

  color: #fff;
}
