.adminaddproduct_card-body {
  background-color: #090909 !important;
  padding: 20px !important;
  /* height: 50% !important; */
  color: white;
}

.adminaddproduct_btn {
  color: white !important;
  background-color: rgb(18, 79, 9) !important;
}
.adminaddproduct_btn_delete {
  color: white !important;
  background-color: rgb(168, 55, 32) !important;
}
.adminaddproduct_btn_addproduct {
  color: white !important;
  background-color: rgb(6, 71, 46) !important;
}
.adminaddproduct_btn_addproduct_main {
  color: white !important;
  background-color: #183b05 !important;
}
